* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #f5f5f7;
  --color-bg-variant: #3f9fff;
  --color-primary: #4799b7;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-black: #1d1d1f;
  --color-muted: #86868b;
  --color-blue-2: #1f1f38;
  --color-green: #5bbebf;
  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

.color-white {
  color: var(--color-white);
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  color: var(--color-black);
  line-height: 1.7;
}

p {
  font-weight: 300;
}

.grey {
  color: var(--color-light);
}

/* ============== GENEARL STYLES ============== */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

h1 {
  font-size: 4.5rem;
  line-height: 1.3;
  color: var(--color-primary);
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

h5 {
  line-height: 1;
  font-size: 1rem !important;
  font-weight: 300 !important;
}

.bigger {
  font-size: 1.1rem;
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 2.5rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }

  #about {
    margin-top: 0rem;
  }

  .experience__container {
    width: var(--container-width-lg);
    margin: unset !important;
  }

  h1 {
    line-height: 1.1;
  }
}
