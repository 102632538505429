header {
  height: auto;
  overflow: hidden;
}

.upload input {
  display: none;
}

/* =============== HEADER SOCIALS ============== */

a {
  --bs-link-color: var(--color-bg-variant);
  text-decoration: none !important;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  bottom: 5rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* =============== upload section ============== */

.uploader-btn {
  width: 100%;
  padding: 60px 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--color-blue-2);
  border: 1px dashed var(--color-bg-variant);
  font-size: 1.2rem;
}

.upload input {
  display: none !important;
}

.upload label:hover {
  background-color: var(--color-white);
  border: 1px solid var(--color-bg-variant);
  color: var(--color-bg-variant);
  cursor: pointer;
}

input:focus::placeholder {
  color: transparent;
}

input,
textarea {
  padding: 1rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 1px solid var(--color-bg-variant);
  resize: none;
  font-size: 1.1rem;
  text-align: center;
}

::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}

.size {
  text-align: left;
}

.description-size {
  color: var(--color-light);
  font-weight: 300;
}

.submit-button-div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.submit-button {
  font-size: 1.1rem;
  padding: 10px 100px;
  border-radius: 8px;
  background-color: var(--color-blue-2);
  color: var(--color-white);
}

.submit-button:hover {
  border: 1px solid var(--color-bg-variant);
  color: var(--color-bg-variant);
  cursor: pointer;
  background-color: var(--color-white);
}

button:disabled:hover {
  background-color: #fafafa;
  color: #b4b4b4;
  cursor: not-allowed;
}

/* =============== SCROLL DOWN ============== */
.scroll__down {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  right: 0;
  bottom: 11rem;
  transform: rotate(90deg);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  header {
    height: auto;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  header {
    height: auto;
    /* added after tutorial */
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  /* added after tutorial */
  .me {
    width: 94%;
    max-width: 20rem;
    margin-top: 2.5rem;
    justify-content: center;
  }

  input,
  textarea {
    width: 100px;
  }

  /* added after tutorial */
}
